import React from "react";

import Header from "./components/header";
import Footer from "./components/footer";
import Slider from "./components/slider";

const IndexPage = () => {
  return (
    <>
      <Header />
      <Slider />
      <main className="bg-gray-200 py-8 space-y-8">
        <div className="mx-auto max-w-6xl container p-8 bg-white space-y-4">
          <p className="font-playfirDisplay italic text-2xl text-gray-600">
            Здравствуйте!
          </p>
          <p className="font-playfirDisplay">
            От лица учредителей рад приветствовать Вас на сайте ООО
            «КФП-Консалтинг».{" "}
          </p>
          <p className="font-playfirDisplay">
            Наше предприятие является одним из подразделений группы компаний
            «Красильников, Фролов и партнеры» и специализируется на оказании
            консалтинговых услуг, преимущественно в сфере обеспечения
            экономической и юридической безопасности бизнеса.
          </p>
          <p className="font-playfirDisplay">
            Его учредителями и сотрудниками являются лица, имеющие многолетний
            опыт работы в правоохранительных и контролирующих органах.
          </p>
          <p className="font-playfirDisplay">
            Мы сотрудничаем как с государственными, так и с крупными
            предпринимательскими структурами, но наиболее востребованы наши
            услуги для предприятий малого и среднего бизнеса.{" "}
          </p>
          <p className="font-playfirDisplay">
            Работая как на абонентской основе, так и решая локальные задачи,
            действуя в рамках полномочий, предоставленных действующим
            законодательством, наши сотрудники проводят комплекс мероприятий по
            ряду направлений, в том числе:
          </p>
          <div className="font-playfirDisplay">
            <ul className="list-inside list-disc px-6">
              <li>
                полное юридическое сопровождение проверок со стороны
                контрольно-надзорных органов - от получения уведомления о
                проверке до обжалования принятых решений в судебном порядке;{" "}
              </li>
              <li>
                представление интересов компании в государственных и
                правоохранительных структурах;
              </li>
              <li>
                текущее юридическое обслуживание коммерческих предприятий и
                общественных организаций, индивидуальных предпринимателей и
                самозанятых граждан;
              </li>
              <li>
                взыскание дебиторской задолженности (проведение переговоров,
                юридическое сопровождение гражданских дел, поиск имущества,
                принадлежащего должнику, организация взаимодействия со службой
                судебных приставов);
              </li>
              <li>проверка потенциальных и действующих контрагентов;</li>
              <li>
                проверка персонала и кандидатов на работу (в том числе и с
                использованием полиграфа);
              </li>
              <li>
                осуществление мер по выявлению и нейтрализации внутренних и
                внешних угроз (утечка конфиденциальной информации, выявление и
                профилактика фактов хищений, неправомерная деятельность
                конкурентов и противодействие ей, обеспечение физической защиты
                персонала);
              </li>
            </ul>
          </div>

          <p className="font-playfirDisplay">
            При необходимости нами организуется физическая охрана объектов,
            разработка и реализация правил пропускного и внутриобъектового
            режима, устанавливаются технические средства охраны.{" "}
          </p>
          <p className="font-playfirDisplay">
            Отдельным направлением деятельности является обеспечение
            информационной защиты наших партнеров.{" "}
          </p>
          <p className="font-playfirDisplay">
            Сертифицированные специалисты проведут изучение состояния дел в
            области защиты информации, разработают нормативно-методическую
            документацию в соответствии с требованиями ГОСТ и надзорных органов,
            организуют защиту информационных систем предприятия с помощью
            технологий виртуальных частных сетей и распределенного межсетевого
            экранирования на серверных мощностях, рабочих станциях и мобильных
            устройствах с применением программных и аппаратных средств, проведут
            приемо-сдаточные испытания системы защиты на соответствие
            требованиям информационной безопасности.{" "}
          </p>
          <p className="font-playfirDisplay">
            Кроме того, обладая определенной информацией о процессах,
            происходящих в республике , мы имеем возможность дать
            профессиональную консультацию по отдельным аспектам ведения бизнеса.
          </p>
          <p className="font-playfirDisplay">
            Агентство «Красильников, Фролов и партнеры» дорожит своей репутацией
            и поэтому наша деятельность полностью соответствует требованиям
            действующего законодательства, она всегда строилась, строится, и
            будет строиться только в целях полного решения поставленных задач и
            оформляться индивидуальным договором, предусматривающим
            исчерпывающий перечень проводимых мероприятий, который может быть
            оперативно изменен даже после устного согласования.{" "}
          </p>
          <p className="font-playfirDisplay">
            При этом мы юридически гарантируем конфиденциальность не только
            содержания договора, но и информации, полученной в ходе его
            исполнения.{" "}
          </p>
          <p className="font-playfirDisplay">
            Мы всегда доступны и всегда готовы оказать квалифицированную помощь
            всем, кто обратился к нам за ней
          </p>
        </div>
        <div className="flex py-8 items-center space-x-8 mx-auto max-w-6xl ">
          <img
            src="https://kfpartners.ru/images/27911020-0afa-4154-9b78-f733f22d61d5.jpg"
            className="rounded-full"
            alt="Валерий Владимирович Красильников"
          />
          <div className="space-y-4">
            <h2 className="text-gray-500 font-playfirDisplay text-xl italic">
              Безопасность – это процесс, а не результат!
            </h2>
            <div>
              <p>Валерий Владимирович Красильников</p>
              <p>
                <em>
                  Генеральный директор, полковник полиции в отставке,
                  Заслуженный юрист РТ
                </em>
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
